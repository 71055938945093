.v-viewport
  position relative
  min-height 100vh
  padding-bottom 200px
  background-color var(--vcolor-grey-lighter)
  box-sizing border-box
  .v-footer
    position absolute
    left 0
    right 0
    bottom 0
