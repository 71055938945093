:root {
  // COLORS
  --vcolor-grey-lighter   : #F1F1F1;
  --vcolor-grey-light     : #F5F5F5;
  --vcolor-grey           : #C6C6C6;
  --vcolor-grey-dark      : #737C84;
  --vcolor-grey-darker    : #474E52;
  --vcolor-red-lighter    : #FFF5F5;
  --vcolor-red-light      : #FBCCCC;
  --vcolor-red            : #FC3338;
  --vcolor-red-dark       : #AC0F0F;
  --vcolor-red-darker     : #A91318;
  --vcolor-blue-lighter   : #BCD1FB;
  --vcolor-blue-light     : #E9F1FB;
  --vcolor-blue           : #6699FF;
  --vcolor-blue-dark      : #2E72FF;
  --vcolor-blue-darker    : #264480;
  --vcolor-lightest       : #FFFFFF;
  --vcolor-darkest        : #333333;
  --vcolor-primary        : var(--vcolor-red);

  // FONT-SIZE
  --vfont-size-xxxs       : 0.625rem; // 10px
  --vfont-size-xxs        : 0.750rem; // 12px
  --vfont-size-xs         : 0.875rem; // 14px
  --vfont-size-sm         : 1.000rem; // 16px
  --vfont-size-md         : 1.125rem; // 18px
  --vfont-size-lg         : 1.250rem; // 20px
  --vfont-size-xl         : 1.500rem; // 24px
  --vfont-size-xxl        : 2.250rem; // 36px
  --vfont-size-xxxl       : 3.000rem; // 48px

  // FONT-FAMILY
  --vfont-family-primary  : 'Inter', Helvetica, Arial, sans-serif;

  // LAYERS
  --vlayer-base           : 01;
  --vlayer-lowest         : 10;
  --vlayer-low            : 20;
  --vlayer-middle         : 30;
  --vlayer-high           : 40;
  --vlayer-highest        : 50;
}
