@import '@mixins'

.v-topbar
  position sticky
  display flex
  align-items center
  top 0
  left 0
  width 100%
  height 60px
  background hsla(0, 0%, 100%, .75)
  border-bottom 1px solid var(--vcolor-grey-light)
  backdrop-filter blur(12px)
  -webkit-backdrop-filter blur(12px)
  z-index var(--vlayer-lowest)
  h1
    position relative
    display flex
    width 84px
    height 40px
    text-indent -9999px
    a
      position absolute
      top 0
      left 0

.v-topbar-content
  display flex
  justify-content space-between
  width 100%

+breakpoint('sm')
  .v-topbar-content
    justify-content center
  .v-topbar-custom-content
    margin-left 20px
