.v-footer
  display flex
  align-items center
  height 150px
  background-color var(--vcolor-lightest)
  border-top 1px solid var(--vcolor-grey-light)

.v-footer-credits
  margin-top 20px
  color var(--vcolor-grey-darker)
  font-size var(--vfont-size-xxs)
  text-align center

.v-footer-configurations
  display flex
  justify-content center
